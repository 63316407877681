import React, { useEffect, useState } from 'react'

const Main = () => {
  const [userInfo, setUserInfo] = useState()
  const EZKEYCODER_ROLE = 'ezkeycoder'
  const [hasEZKeyCoderRole, setHasEZKeyCoderRole] = useState(false)

  const [hardwareCode, setHardwareCode] = useState<string>('')
  const [controlNumber, setControlNumber] = useState<string>('')
  const [keyCode, setKeyCode] = useState<string | null>(null)
  const [keyCodeErrorMessageLines, setKeyCodeErrorMessageLines] = useState<
    string[]
  >([])
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

  const onHardwareCodeChange = (value: string) => {
    setHardwareCode(value)
    setKeyCode(null)
    setKeyCodeErrorMessageLines([])
  }

  const onControlNumberChange = (value: string) => {
    setControlNumber(value)
    setKeyCode(null)
    setKeyCodeErrorMessageLines([])
  }

  const handleEnterKey = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleSubmit()
    }
  }

  const handleSubmit = async () => {
    try {
      if (hardwareCode && controlNumber) {
        const baseUrl = `${process.env.REACT_APP_BACKEND_BASE_URI}/GetKeyCode`
        const url = `${baseUrl}?hardwareCode=${encodeURIComponent(
          hardwareCode
        )}&controlNumber=${encodeURIComponent(controlNumber)}`
        const response = await fetch(url)
        const responseJson = await response.json()
        if (responseJson) {
          if (responseJson['keyCode']) {
            setKeyCode(responseJson['keyCode'])
          } else if (responseJson['errorMessage']) {
            setKeyCodeErrorMessageLines(
              responseJson['errorMessage'].split('\n')
            )
          }
        } else {
          setKeyCodeErrorMessageLines(['Unable to generate a key code.'])
        }
      }
    } catch (error) {
      console.error(error)
      setKeyCodeErrorMessageLines([
        'Unable to generate a key code due to exception.',
      ])
    }
  }

  const handleCopyToClipboard = () => {
    if (keyCode) {
      navigator.clipboard
        .writeText(keyCode)
        .then(() => {
          setTooltipVisible(true)
          setTimeout(() => {
            setTooltipVisible(false)
          }, 1000)
        })
        .catch((error) => {
          // console.error('Failed to copy text: ', error);
        })
    }
  }

  useEffect(() => {
    ;(async () => {
      const clientPrincipal = await getUserInfo()
      setUserInfo(clientPrincipal)
      const useRoles: string[] = clientPrincipal
        ? clientPrincipal['userRoles']
        : []
      const checkUserRole =
        Array.isArray(useRoles) && useRoles.includes(EZKEYCODER_ROLE)
      const claims: any[] = clientPrincipal ? clientPrincipal['claims'] : []
      const checkRoleClaim =
        claims.find((claim) => {
          const typ = claim['typ']
          const val = claim['val']
          return (
            typeof typ === 'string' &&
            typ.includes('role') &&
            val === EZKEYCODER_ROLE
          )
        }) !== undefined
      setHasEZKeyCoderRole(checkUserRole || checkRoleClaim)
    })()
  }, [])

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me')
      const payload = await response.json()
      const { clientPrincipal } = payload
      return clientPrincipal
    } catch (error) {
      return undefined
    }
  }

  return (
    <div className="content-container">
      <div className="content-title-group">
        <h2 className="title">Code Generation</h2>

        {!userInfo && (
          <>
            <p>
              Please{' '}
              <a href="/.auth/login/aad?post_login_redirect_uri=/main">
                <strong>log in</strong>
              </a>{' '}
              using your BRP Azure Entra ID to generate your unique key code.
            </p>
          </>
        )}

        {userInfo && !hasEZKeyCoderRole && (
          <>
            <p>
              Please contact your system administrator to get access to the
              application.
            </p>
            <div>
              <p className="instruction--note">
                Please{' '}
                <a href="/.auth/logout?post_logout_redirect_uri=/main">
                  <strong>log out</strong>
                </a>{' '}
                for now.
              </p>
            </div>
          </>
        )}

        {hasEZKeyCoderRole && (
          <>
            <p>
              Please fill in the information below to generate your key code.
            </p>
            <div>
              <div className="field-wrapper">
                <label htmlFor="control-number">
                  <strong>Control Number:</strong>
                </label>
                <input
                  type="text"
                  id="control-number"
                  value={controlNumber}
                  onChange={(e) => onControlNumberChange(e.target.value)}
                  onKeyDown={handleEnterKey}
                />
              </div>
              <div className="field-wrapper">
                <label htmlFor="hardware-code">
                  <strong>Hardware Number:</strong>
                </label>
                <input
                  type="text"
                  id="hardware-code"
                  value={hardwareCode}
                  onChange={(e) => onHardwareCodeChange(e.target.value)}
                  onKeyDown={handleEnterKey}
                />
              </div>
              <div className="field-wrapper">
                <button
                  className="button is-dark is-small"
                  onClick={handleSubmit}
                  disabled={!hardwareCode || !controlNumber}
                >
                  Submit
                </button>
              </div>

              {keyCode && (
                <div className="field-wrapper">
                  <label htmlFor="key-code">
                    <strong>Your key code is:</strong>
                  </label>
                  <input type="text" id="key-code" value={keyCode} disabled />
                  <i
                    id="key-code-icon"
                    className="far fa-copy copy-icon"
                    onClick={handleCopyToClipboard}
                    aria-label="copy key code"
                  />
                  {tooltipVisible && <div className="tooltip">Copied!</div>}
                </div>
              )}

              {keyCodeErrorMessageLines?.length > 0 && (
                <div className="field-wrapper">
                  <p>
                    <span className="error-message">
                      {keyCodeErrorMessageLines.map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== keyCodeErrorMessageLines.length - 1 && (
                            <br />
                          )}
                        </React.Fragment>
                      ))}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div>
              <p className="instruction--note">
                <strong>Note:</strong> For security purpose, please remember to{' '}
                <a href="/.auth/logout?post_logout_redirect_uri=/main">
                  <strong>log out</strong>
                </a>{' '}
                after you are done.
              </p>
            </div>
          </>
        )}
        {hasEZKeyCoderRole && (
          <div className="instructions">
            <h2 className="title">Additional Information</h2>
            <div className="download-section">
              <h3>BAM II</h3>
              <ul>
                <li>
                  <a href="/downloads/Setup_BAM_433_2024.exe" download>
                    <i className="fas fa-download"></i>BAM II 4.3.3 Installer
                  </a>
                </li>
                <li>
                  <a href="/downloads/Setup_BAM_434_2024.exe" download>
                    <i className="fas fa-download"></i>BAM II 4.3.4 Installer
                  </a>
                </li>
              </ul>
            </div>

            <div className="download-section">
              <h3>BAM II Powerpacks</h3>
              <ul>
                <li>
                  <a
                    href="/downloads/Setup_BAM_Powerpacks_100_2024.exe"
                    download
                  >
                    <i className="fas fa-download"></i>BAM II Powerpacks
                    Installer
                  </a>
                </li>
              </ul>
            </div>

            <div className="download-section">
              <h3>HASP Drivers</h3>
              <ul>
                <li>
                  <a href="/downloads/HASPUserSetup.exe" download>
                    <i className="fas fa-download"></i>HASP Runtime Drivers
                  </a>
                </li>
              </ul>
            </div>

            <div className="download-section">
              <h3>BAM II User Manual</h3>
              <ul>
                <li>
                  <a href="/downloads/BAM_AES_User_Manual.pdf" download>
                    <i className="fas fa-download"></i>BAM II AES User Manual
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Main
