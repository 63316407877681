import React, { Suspense } from 'react'
import './styles.scss'
import { Redirect, Route, Switch } from 'react-router-dom'
import { HeaderBar } from './components'
import Main from './Main'

export default class App extends React.Component {
  render() {
    return (
      <div>
        <HeaderBar />
        <main className="column">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Redirect from="/" exact to="/main" />
              <Route path="/main" component={Main} />
              <Route exact path="**" component={Main} />
            </Switch>
          </Suspense>
        </main>
      </div>
    )
  }
}
