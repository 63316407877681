import React from 'react'
import HeaderBarBrand from './HeaderBarBrand'

const HeaderBar = () => (
  <header>
    <nav className="brp-navbar" role="navigation" aria-label="main navigation">
      <HeaderBarBrand />
    </nav>
  </header>
)

export default HeaderBar
