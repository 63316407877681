import React from 'react'
import { NavLink } from 'react-router-dom'

const HeaderBarBrand = () => (
  <div className="brp-navbar-brand">
    <NavLink to="/" className="brp-navbar-item brp-nav-home">
      <img src="/logo-brp.svg" alt="BRP logo" />
      <span>BRP EZ Key Code</span>
    </NavLink>
  </div>
)

export default HeaderBarBrand
